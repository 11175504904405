.table_amount{
    color: #8cc65d !important;
    font-weight: 600 !important;
    font-size: 15px !important;
}

.table_value{
    color: #0081c6 !important;
    font-weight: 600 !important;
    font-size: 15px !important;
}

.table_year{
    color: rgb(129, 125, 125) !important;
    font-weight: 600 !important;
    font-size: 15px !important;
}

.nr1{
    color: rgb(129, 125, 125) !important;
    font-weight: 600 !important;
}

.nr2{
    font-weight: 600 !important;
    color: #0081c6;
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px !important;
    text-align: right;
}

.nr3{
    color: #8cc65d !important;
    font-weight: 600 !important;
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px !important;
    text-align: right;
}

@media (max-width: 812px){
    .table_amount{
        color: #8cc65d !important;
        font-weight: 600 !important;
        font-size: 12px !important;
    }
    
    .table_value{
        color: #0081c6 !important;
        font-weight: 600 !important;
        font-size: 12px !important;
    }
    
    .table_year{
        color: rgb(129, 125, 125) !important;
        font-weight: 600 !important;
        font-size: 12px !important;
    }
    
}