.backdrop{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0,0,0,0.7);

    .login{
        border-radius: 5px;
        width: 500px;
        background-color: #fff;
        box-shadow: 0px 0px 95px -10px rgba(0,0,0,0.75);
        position: absolute;
        height: 298px;
        top: -291px;
        left: calc(50% - 250px );
        animation: mymove .5s forwards;
        overflow: hidden;

        @keyframes mymove{
            from{
                top: -260px;
            }
            to{
                top: calc(50% - 150px );
            }
        }
    
        .login-inputs{
            width: 100%;
            padding: 30px 10px;
            
            h5{
                color: #0069D9;
                font-weight: bold;
            }
    
            input{
                width: 100%;
                border: 1px solid #0069D9;
                border-left: 5px solid #0069D9;
                border-radius: 5px;
                padding: 10px;
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }
    
        .login-button{
            button{
                width: 100%;
                border-radius: 0;
                padding: 13px;
                margin-top: 20px;
            }
        }
        
        .login-remove{
            position: absolute;
            right: 20px;
            top: 20px;
            background-color: none;
            border: none;
            color: red;
            font-weight: bold;
            transition: all .15s linear;
        }

        .login-remove:hover{
            transform:scale(1.1);
        }
    }
    
    @media screen and (min-width: 100px) and (max-width: 1200px) {
        .login{
            width: 340px;
            left: calc(50% - 170px );
        }
    }
}

.dashboard ul{
    list-style: none;
    padding: 0;
    padding-top: 20px;
}

.dashboard ul li{
    margin: 2px;
}

.login-title-uploads{
    color: #0069D9;
    font-weight: 600;
}
.table-backdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 50px;
    z-index: 1;
    background-color: rgba(0,0,0,0.7);
}


.table-tab{
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #fff;
    border-radius: 5px;
    overflow-x: hidden;
    animation: ts 1s forwards;
}

@media screen and(max-width: 1200px) and(min-width:  0){
    .table-tab{
        overflow-x: scroll;
    }
}
.table-buttons{
    border-top: 2px solid black;
}

.table-buttons button{
    width: 200px;
}

@keyframes ts {
    from {
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}


  
  input[type="file"] {
    cursor: pointer !important;
    color:  rgb(0, 128, 0);
  }

  input[type="file"]::-webkit-file-upload-button {
    border: none;
    padding: 5px 12px;
    background: #0069D9;
    color: #fff;
    font-size: 1em;
    transition: all .4s;
    cursor: pointer;
    border-radius: 5px;
  }

  input[type="file"]::-ms-browse {
    border: none;
    padding: 5px 12px;
    background: #0069D9;
    color: #fff;
    font-size: 1em;
    transition: all .4s;
    cursor: pointer;
    border-radius: 20px;
  }
  

  .uploadingHidrometeorology{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left:0;
      background-color: #0069D9;
      color: #fff;
      text-align: center;
      padding: 20px;
  }

  .spinner-border{
      position: absolute;
      top: calc(50% - 30px);
      left: calc(50% - 30px);
      width: 60px !important;
      height: 60px !important;
  }


.uploaded-successfuly{
    margin-top: 53px;
    color: rgb(11, 185, 63);
    font-weight: bold;
    font-size: 18px;
    animation: showup .5s forwards;

    i{
        font-size: 26px;
    }
}

@keyframes showup{
    from{
        opacity: 0;
        margin-top: 13px;
    }
    to{
        margin-top: 53px;
        opacity: 1;
    }
}

.dsh{
    height: 100%;
}

.error-box-backdrop{
    position: absolute;
    width: 100%;
    height: calc(100vh - 150px);
    background-color: rgba(0,0,0,0.6);
    left: 0;
    top: 0;
    z-index: 100;
    padding: 30px;
    
    .error-box{
        width: 100%;
        height: 100%;
        background-color: #fff;
        padding: 30px;
        overflow: hidden;
        p{
            float: left;
            display: table;
        }

        a{
            float: right;
        }

        tr{
            font-size: 12px;
            th{
                text-transform: capitalize;
            }
        }
        
        .table-content{
            overflow: auto;
            width: 100%;
            height: 94%;
            overflow-x: auto;
        }

        p{
            color: red;
            font-size: 20px;
            font-weight: 600;
        }
    }
}