.hover-data {
	
	display: table;
	border: 1px solid black;
	background-color: black;
	border-radius: 5px;
	color: #fff;
	padding: 5px 15px;
	position: absolute;
	transition: all .15s linear;
	opacity: 1;
	font-size: 11px;

	p{
		margin: 0;
	}

}

.prish{
	top: 200px;
	left: 250px;
}

.mit{
	top: 100px;
	left: 50px;
}

.pej{
	top: 190px;
	left: 0px;
}

.priz{
	top: 420px;
	left: 50px;
}

.gjak{
	top: 290px;
	left: 0px;
}

.gjil{
	top: 270px;
	left: 330px;
}

.fer{
	top: 350px;
	left: 230px;
}

.set:hover {
	cursor: pointer;
}

@media (max-width: 1600px) and (min-width: 1200px){

	.hover-data{
		display: table;
		border: 1px solid black;
		background-color: black;
		border-radius: 5px;
		color: #fff;
		padding: 5px 15px;
		position: absolute;
		transition: all .15s linear;
		opacity: 1;
		font-size: 11px;
	
		p{
			margin: 0;
		}
	
	}
	
	.prish{
		top: 150px;
		left: 180px;
	}
	
	.mit{
		top: 100px;
		left: 0px;
	}
	
	.pej{
		top: 160px;
		left: -50px;
	}
	
	.priz{
		top: 320px;
		left: 50px;
	}
	
	.gjak{
		top: 240px;
		left: -70px;
	}
	
	.gjil{
		top: 210px;
		left: 260px;
	}
	
	.fer{
		top: 260px;
		left: 170px;
	}
    
}


@media (max-width: 1200px) and (min-width: 0) {

	.hover-data{
		display: table;
		border: 1px solid black;
		background-color: black;
		border-radius: 5px;
		color: #fff;
		padding: 5px 15px;
		position: absolute;
		transition: all .15s linear;
		opacity: 1;
		font-size: 11px;
	
		p{
			margin: 0;
		}
	
	}
	
	.prish{
		top: 150px;
		left: 180px;
	}
	
	.mit{
		top: 100px;
		left: 0px;
	}
	
	.pej{
		top: 160px;
		left: 0px;
	}
	
	.priz{
		top: 320px;
		left: 50px;
	}
	
	.gjak{
		top: 240px;
		left: -10px;
	}
	
	.gjil{
		top: 210px;
		left: 260px;
	}
	
	.fer{
		top: 260px;
		left: 170px;
	}
    
}
