.navbar__list__group{
    display: table;
    list-style: none;
    margin: 0;
    float: right;
    padding: 4px 0px;

    .item{
        float: left;
        padding-bottom: 0;
        padding-left: 10px;
        
        .link{
            display: block;
            padding: 14px 5px;
            color: #8cc65d;
            font-weight: bold;
            letter-spacing: -0.4px;
            font-size: 11px;
            transition: all .15s linear;
            padding-top: 14px;
            padding-bottom: 3px;
            border-bottom: 3px solid transparent;
            line-height: 2.3;
        }

        .link:hover:not(.navbar__social__links){
            border-bottom: 3px solid #8cc65d;
            color: #8cc65d;
            text-decoration: none;
        }
    }
}

.navbar--socialitem{
    padding-left: 0px;
}



.navbar__social__links{
    float: left;
    padding-top: 10px;
    padding-left: 3px;
}

.navbar__social__links i{
    padding: 5px 8px;
    font-size: 15px;
    border-radius: 50%;
    background-color: #0081c6;
    color: #fff;
}

.fb i{
    padding: 6px 10px 4px;
}
.inst i{
    padding: 5px 7px 5px !important;
}
.tw i{
    padding: 5px 6px 5px !important;
}


@media(max-width: 812px){
    .navbar__list__group{
        display: table;
        list-style: none;
        margin: 0;
        text-align: center;
        padding: 4px 0px;
    
        .item{
            float: left;
            padding-bottom: 0;
            padding-left: 5px;
            
            .link{
                display: block;
                padding: 14px 1px;
                color: #8cc65d;
                font-weight: bold;
                letter-spacing: -0.4px;
                font-size: 10px;
                transition: all .15s linear;
                padding-top: 14px;
                padding-bottom: 3px;
                border-bottom: 3px solid transparent;
            }
    
            .link:hover:not(.navbar__social__links){
                border-bottom: 3px solid #8cc65d;
                color: #8cc65d;
                text-decoration: none;
            }
            .navbar__social__links i{
                // padding: 5px 8px;
                font-size: 12px;
                border-radius: 50%;
                background-color: #0081c6;
                color: #fff;
            }
        }
    }
    
}