footer {
    background: #2f2f2f;
    padding: 10px;
    color: #8cc65d;
    font-size: 14px;

    .row {
        margin-right: 10px;
        a {
            p {
                color: #8cc65d;
            }

            &:hover {
                text-decoration: none;
            }
        }
        p , a {
            margin: 0;
            padding: 5px 0;
            font-weight: bold;
    
            span {
                font-size: 20px;
            }
        }
    }

    
}