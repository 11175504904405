.grants {
    .title {
        font-weight: 600;
        color: #0081c6;
        text-transform: uppercase;
    }
    
    .filters {
        padding-top: 20px;
    }

    .chart {
        // padding-top: 50px;
    }
}
/* edition of radio buttons */