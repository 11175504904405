.doughnout{
    height: 250px;
    width: 250px;

    canvas{
        margin: 0 auto;
        height: 200px !important;
        width:200px !important;
    }
    
}


