.bodyRows {
    border:none;

    td {
        vertical-align: middle;
        border: none;
        min-width: 43px;

        img {
            margin-top: 5px;
            margin-left: 3px;
            max-height: 40px;
            max-width: 28px;
        }
    }

    td:nth-child(2n) img {
        float: right;
        margin-right: 3px;
        margin-left: 0px;
    }

    .activity {
        padding: .5em;
        padding-left: 20px;
        font-size: 13px;
        height: 45px;
        width: 200px;
    }


    /* ME VERIFIKU */

    /*
        .table__bodyRows td.green, 
        .table__bodyRows  {
            padding-left: 10px;
        }
    */
    td {
        height: 45px;
    }
    td.green,
    td.orange {
        padding-left: 10px;
    }

    /* Color & Background */

    .column--orange {
        color: #f68426;
    }

    .column--green {
        color: #8cc65d;
    }
}

.bodyRows td:nth-child(2n+1) {
    border-right: 10px;
}

.bodyRows td:nth-child(2n+2) {
    border-left: 10px;
}

/* Borders */
.greenBackground td:first-child,
.orangeBackground td:first-child {
    border-left: 10px solid #fff;
    vertical-align: middle;
}

.greenBackground:nth-child(2n) {
    background: #eefae3;
}

.orangeBackground:nth-child(2n+1) {
    background: #ffe2ca;
}