.chart-test{
    height: 400px;

    canvas{
        height: 100%;
    }
}

@media (max-width: 960px){
    .chart-test{
        height: 300px;

        canvas{
            height: 100%;
        }
    }
}
