.information__content {
    font-size: 14px;
    font-weight: 500;
    padding-top: 20px;

    .name {
        .title {
            margin-bottom: 0px;
            font-weight: bold;
            color: #0081c6;
        }
    }

    .details {
        .title {
            margin-bottom: 0px;
            font-weight: bold;
            color: #0081c6;
        }
        .content {
            margin-bottom: 0px;
        }
    }
}