.production__table{
    
.ReactTable .rt-thead.-header {
    box-shadow: none !important;
    border-bottom: 1px solid #0081c6;
  }
  
  .rt-th{
      font-weight: 600;
      font-size: 13px;
      padding: 10px 5px !important;
      width: 50px !important;
  }

  .rt-td{
    box-shadow: none;
  }
  
  .ReactTable{
    border: none !important;
    width: 100%;
  }
  
  
  .grants__table table {
    width: 100%;
  }
  
  .ReactTable .rt-thead .rt-tr {
    font-size:12px;
    color: #0081c6;
  }
  
  .ReactTable .rt-thead .rt-resizable-header{
    /* padding: 5px; */
    text-align: left;
    padding: 20px !important;
  }
  
  .rt-tr-group{
    border-bottom: 1px solid #0081c6 !important;
  }

  .rt-td {
    padding: 9px !important;
    font-family: "Courier New", Courier, monospace;
    font-size: 12px;
    font-weight: 600;
    color: #2c3847;
    text-align: right;
  }

  .rt-td:first-child {
	padding: 10px !important;
	font-family: "Montserrat", sans-serif;
    font-size: 10px;
    font-weight: 600;
    color: #2c3847;
  }

  .rt-tr .rt-td:nth-child(2), .rt-td:nth-child(3), .rt-tr .rt-td:nth-child(4), .rt-tr .rt-td:nth-child(5){
    color: #f68426;
    font-weight: 600;
  }
  
  .grants__table__cell__number{
    color: #f68426;
    font-weight: bold;
  }
  
.-sort-asc{
    box-shadow: none !important;
    border-top: 3px solid #0081c6 !important;
  }
  
  .-sort-desc{
    box-shadow: none !important;
    border-bottom: 3px solid #0081c6 !important;
  } 
  
  .rt-th section{
    text-align: right;
  }
}
