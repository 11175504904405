.information {
    .title {
        font-weight: bold;
        color: #0081c6;
        text-transform: uppercase;
    }

    .information_chart {
        font-size: 14px;
        font-weight: 500;
        padding-top: 25px;

        .title {
            font-size: 14px;
        }
    }
}