.sidebar{
    width: 100%;
    height: 100%;
    background-color: #0081c6;
    padding-top: 30px;
    padding-bottom: 100px;
}

.sidebar__item{
    // border: 1px solid red;
    display: table;
    padding: 10px;
    padding-left: 20px;
}


.sidebar__item div{
    float: left;
}

.sidebar__img{
    width: 40px;
}

.sidebar__img img{
    width: 40px;
}

.sidebar__link{
    padding-left: 15px;
    width: calc(100% - 40px);
}

.sidebar__link a{
    color: #fff;
    font-size: 16px;
}

.sidebar__link a:hover{
    text-decoration: none;
    color: #fff;
    cursor: pointer;
}

.sidebar__submenu{
    list-style: none;
    padding: 10px 20px;
}

.sidebar__submenu a{
    font-size: 13px;
    transition: all .15s linear;
}

.sidebar__submenu a:hover{
    font-weight: 600;
    font-size: 13px;
}

@media (max-width:1450px) and (min-width:0px) {
 
  .sidebar__submenu{
        list-style: none;
        padding: 5px 5px;
    }

    .sidebar__link a{
        color: #fff;
        font-size: 14px;
    }

    .sidebar__submenu a{
        font-size: 11px;
        transition: all .15s linear;
    }

    .sidebar__submenu a:hover{
        font-weight: 600;
        font-size: 11px;
    }

}

@media (max-width: 1200px){
    .sidebar{
        display: none;
    }
}