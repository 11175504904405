.data-source{
    
    .table-header-font{
        font-weight: 600;
    }

    th{
        font-weight: normal;
        align-self: center;
        color: #033d5c;
    }

    .table td, .table th{
        border-bottom: 1px solid #033d5c !important;
    }

    .sub-title{
        font-weight: bold;
        color: #0081c6;
        text-transform: uppercase;
    }

    .left-tr{
        width: 250px;
    }

}
