.filter__block__radiobuttons__input--year[type='radio']:after {
    width: 17px;
    height: 17px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #fff;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #cfd8dc;
}

.filter__block__radiobuttons__input--year[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #8cc65d;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #cfd8dc;
}

.culture__filter {
    label.title {
        font-weight: 500;
        color: #000;
        text-transform: none;
    }
    
    select {
        border: 1px solid #0081c6;
        width: 100%;
        padding:10px 5px;
        font-size: 11px;
        border-radius:3px;
    }
 }

 .general__filter{
    padding-top: 20px;

    label.title {
        font-weight: 500;
        color: #000;
        text-transform: none;
    }
    
    .radiobuttons{
        padding-left: 19px;

        form{
            font-size: 14px;

            .ul {
                list-style-type: none;
                padding-left: 0;
    
                .input{
                    margin: 10px 0px;
                    font-size: 14px;
                    margin-right: 10px;
                }

                .input[type='radio']:after {
                    width: 15px;
                    height: 15px;
                    border-radius: 15px;
                    top: -2px;
                    left: -1px;
                    position: relative;
                    background-color: #fff;
                    content: '';
                    display: inline-block;
                    visibility: visible;
                    border: 2px solid #cfd8dc;
                }
                
                .input[type='radio']:checked:after {
                    width: 14px;
                    height: 14px;
                    border-radius: 15px;
                    top: -2px;
                    left: -1px;
                    position: relative;
                    background-color: #8cc65d;
                    content: '';
                    display: inline-block;
                    visibility: visible;
                    border: 2px solid #cfd8dc;
                }
            }
        }
    }
}