@import url('https://fonts.googleapis.com/css?family=Montserrat:200i,300,300i,400,400i,500,600,600i,700');


* {
    font-family: 'Montserrat', sans-serif;
}

/* p, h1, h2, h3, h4, h5, h6{
    margin: 0 !important;
    padding: 0 !important;
} */

.logo_ad_nav{
    -webkit-box-shadow: 0px 6px 5px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 6px 5px -3px rgba(0,0,0,0.75);
    box-shadow: 0px 4px 5px -3px rgba(0, 0, 0, 0.75);
    z-index: 1;
}

/* sidebar */



/* width */


/* sidebar */



/* bottom-bar */


/* bottom-bar */


/* logo */
    

/* advertisement */


/* production */

.production__title{
    font-weight: bold;
    color: #0081c6;
}

.chart__rotate__text{
    font-weight: bold;
    color: #666666;
    font-size: 14px;
    position: absolute;
    top: 220px;
    left: -25px;
    transform: rotate(-90deg);
}

.production__filter{
    padding-top: 100px;
}

.production__filter__block{
    padding-top: 20px;
}

.production__filter__block__select{
    border: 1px solid #0081c6;
    width: 100%;
    padding:10px 5px;
    font-size: 11px;
    border-radius:3px;
}

.production__filter__block_radiobuttons{
    padding-left: 20px;
}

.production__filter__block_radiobuttons__input{
    margin: 10px 0px;
    font-size: 14px;
    margin-right: 10px;
}

.production__filter__block_radiobuttons form{
    font-size: 14px;
}

.production__filter__block_radiobuttons__input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #fff;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #cfd8dc;
}

.production__filter__block_radiobuttons__input[type='radio']:checked:after {
    width: 14px;
    height: 14px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #8cc65d;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #cfd8dc;
}

.production .ReactTable .rt-td span {
padding: 0 !important;
}


/* map */

.mapdiv{
    background-color: #fff;
    z-index: 1;
    padding-top: 100px;
}


 .mapdiv path{
    transition: all .15s linear;
}

 .mapdiv path:hover {
    fill:#0081c6 !important;
    cursor: pointer;
}

.map_active {
    fill:#0081c6 !important;
}

/* map */


/* edition of radio buttons */
.production__table table{
    width: 100%;
}
.production__table tr{
    border-bottom: 1px solid #0081c6;
}

.production__table th{
    font-size:12px;
    color: #0081c6;
    padding: 15px 10px;
    font-weight: normal;
}

.production__table td{
    font-size:12px;
    padding: 15px 10px;
}

.production__table__cell__number{
    color: #f68426;
    font-weight: bold;
}

/* production */


/* tregtia */

.tregtia__filter__title{
    font-weight: bold;
    color: #0081c6;
}

.tregtia__filter__block{
    padding-top:20px;
}

.tregtia__filter__block__select{
    border: 1px solid #0081c6;
    width: 250px;
    padding:10px 5px;
    font-size: 13px;
    border-radius:3px;
}

.tregtia__filter__block__subtitle{
    font-weight: 600;
}

.tregtia__filter__block__radiobuttons{
    font-size:13px;
    padding-left: 20px;
}

.tregtia__filter__block__radiobuttons__input{
    margin: 10px 0px;
    font-size: 14px;
    margin-right: 10px;
}

.tregtia__filter__block__radiobuttons__input[type='checkbox']:after {
    width: 15px;
    height: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #f1f1f1;
}

.tregtia__filter__block__radiobuttons__input[type='checkbox']:checked:after {
    width: 17px;
    height: 17px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #8cc65d;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

.tregtia .tables{
    padding-bottom:40px;
}

.table__single__title{
    color: #fff;
    background-color: #0081c6;
    text-align: center;
    padding: 10px;
}

.table__single__title p{
    font-size: 16px;
    padding: 0;
    margin: 0;
    font-weight: 600;
}

.table__single__title h6{
    padding: 0;
    margin: 0;
}

.tables__title{
    font-weight: bold;
    color: #0081c6;
}


.table__single{
    width: 100%;
}

.table__single tr{
    border-bottom: 1px solid #0081c6;
}

.table__single tr th{
    padding:6px;
    font-size:12px;
    color: #0081c6;
    padding: 15px 10px;
    font-weight: normal;
    text-align: right;
}

.table__single tr th:first-child{
    padding:6px;
    font-size:12px;
    color: #0081c6;
    padding: 15px 10px;
    font-weight: normal;
    text-align: left;
}

.table__single tr td{
    font-size:12px;
    padding: 10px;
    font-weight: normal;
    padding: 14px 10px;
}

.table__single tr td.nr{
    color: #8cc65d;
    font-weight: bold;
}
/* tregtia */


/* general classes */
.act{ 
    
}

.active{
    font-weight: bold;
    border-bottom: 3px solid #8cc65d !important;
}


.active .nav-link{
    color: #0081c6 !important;
    font-weight: bold !important;
}

.active_sidebar{
    font-weight: bold;
    color: #fff !important;
}

.p__t{
    padding:20px 5px;
}

/* general classes */

/* BEM Methodology */


.pages__content{
    padding:20px; 
}

@media only screen and (max-width: 768px) {
    .pages__content{
        padding: 15px;
    }
}


/* general classes */


/* bank */
.bank__filter{
    padding-top: 15px;
}

.bank__filter__block{
    padding-left: 20px;
    font-size:14px;
}

.filter__block_radiobuttons__input{
    margin: 10px 0px;
    font-size: 14px;
    margin-right: 10px;
}

.filter__block__radiobuttons__input--year[type='radio']:after {
    width: 17px;
    height: 17px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #fff;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #cfd8dc;
}

.filter__block__radiobuttons__input--year[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #8cc65d;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #cfd8dc;
}

.filter__block__radiobuttons__input--kredi[type='checkbox']:after {
    width: 15px;
    height: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #f1f1f1;
}

.filter__block__radiobuttons__input--kredi[type='checkbox']:checked:after {
    width: 15px;
    height: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #8cc65d;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

.filter__block__radiobuttons__input--activity:nth-child(1) label{
    margin-right: 40px;
}

.filter__block__radiobuttons__input--activity[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #fff;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #cfd8dc;
}

.filter__block__radiobuttons__input--activity[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #8cc65d;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #cfd8dc;
}

.circle__chart__title{
    background-color: #0081c6;
    color: #fff;
    display: table;
    padding: 10px 30px !important;
    font-size: 25px;
    font-weight: bold;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
}

.doughnout__chart__text{
    font-size: 26px;
    position: absolute;
    top: 47%;
    left: 15px ;
    width: 100%;
}
/* bank */

/* .chartjs-render-monitor{
    width: 400px !important;
    height: 200px !important;
} */


/* navbari */

.navbar-toggler{
    border: none;
}

.navbar-toggler-icon{
    color: red !important;
}

/* navbari */

@media only screen and (max-width: 600px) {
    .mapdiv{
        width: 300px;
        margin: 0px auto;
        margin: 0px auto;
    }

     .mapdiv svg{
        width: 300px;
        
    }

    /* .circle__chart__title{
        font-size: 14px;
        width: 100%;
        top: 0;
    } */

    .chart__rotate__text{
        top: 100px;
        font-size: 10px;
    }
}


/* information */


/* map */

svg{
    width: 405px;
    margin: 0px auto;

}

.map{
    padding-top: 100px;
}

.mapdiv{    
    width: 405px;
    height: 460px;
    overflow: hidden;
    z-index: 1;
    margin: 0 auto;
    display: inline;
}


@media(min-width: 1200px) and (max-width: 1600px) {
    .mapdiv {
        width: 270px !important;
    }
    svg {
        width: 270px;
    }
} 

#mitrovice{
    fill: #abe172;
}

#peje{
    fill: #d1f5af;
}

#gjakove{
    fill: #8dc65d;
}

#prishtine{
    fill: #c1ed96;
}

#prizren{
    fill: #abe172;
}

#gjilan{
    fill: #8dc65d;
}

#ferizaj{
    fill: #d1f5af;
}

input[type='radio'], input[type='checkbox']{
    cursor: pointer;
}

@media only screen and (max-width: 768px) {

    .filter__block__radiobuttons__input--year[type='radio']:after {
        width: 20px;
        height: 20px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #fff;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid #cfd8dc;
    }
    
    .filter__block__radiobuttons__input--year[type='radio']:checked:after {
        width: 20px;
        height: 20px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #8cc65d;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid #cfd8dc;
    }

    .tregtia__filter__block__radiobuttons__input[type='checkbox']:after {
        width: 20px;
        height: 20px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #d1d3d1;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid #f1f1f1;
    }

    .tregtia__filter__block__radiobuttons__input[type='checkbox']:checked:after {
        width: 20px;
        height: 20px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #8cc65d;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
    }

    .filter__block__radiobuttons__input--kredi[type='checkbox']:after {
        width: 20px;
        height: 20px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #d1d3d1;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid #f1f1f1;
    }
    
    .filter__block__radiobuttons__input--kredi[type='checkbox']:checked:after {
        width: 20px;
        height: 20px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #8cc65d;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
    }
    
    .filter__block__radiobuttons__input[type='radio']:after {
        width: 20px !important;
        height: 20px !important;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #fff;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid #cfd8dc;
    }

    
    .filter__block__radiobuttons__input[type='radio']:checked:after {
        width: 20px !important;
        height: 20px !important;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #8cc65d;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid #cfd8dc;
    }

    .filter__block__radiobuttons__input[type='checkbox']:after {
        width: 20px !important;
        height: 20px !important;
        /* border-radius: 15px; */
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #fff;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid #cfd8dc;
    }

    
    .filter__block__radiobuttons__input[type='checkbox']:checked:after {
        width: 20px !important;
        height: 20px !important;
        /* border-radius: 15px; */
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #8cc65d;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid #cfd8dc;
    }

    .filter__block__radiobuttons__input--activity[type='radio']:after {
        width: 20px;
        height: 20px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #fff;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid #cfd8dc;
    }
    
    .filter__block__radiobuttons__input--activity[type='radio']:checked:after {
        width: 20px;
        height: 20px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #8cc65d;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid #cfd8dc;
    }

    .doughnout__chart__text{
        font-size: 26px;
        position: absolute;
        top: 47%;
        left: 0px ;
        width: 100%;
    }
    
}

@media (max-width: 812px){
    .ReactTable .rt-tbody .rt-td:last-child {
        padding-left: 0 !important;
    }

    .rt-th{
        font-size: 10px !important;
    }

    
    .table__single__title{
        color: #fff;
        background-color: #0081c6;
        text-align: center;
        padding: 10px;
    }
    
    .table__single__title p{
        font-size: 12px;
        padding: 0;
        margin: 0;
        font-weight: 600;
    }
}