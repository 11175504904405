.seasonality {
    .title {
        font-weight: bold;
        color: #0081c6;
        text-transform: uppercase;
    }

    .col-lg-8 {
        .cultureName {
            opacity: 0;
        }
        .culture {
            opacity: 0;
        }
        .activity {
            opacity: 0;
            height: 46.4px;
        }
    }

    .col-lg-4 {
        .activity {
            width: 200px;
        }

        .table {
            overflow: hidden;
        }
    }

    .tableContainer {
        overflow-x: auto;
        min-height: 70px;
        
        table {
            margin-top: 10px;
            font-weight: bold;

            .headRows {
                border:none;
                border-bottom: 1px solid #0081c6;

                th {
                    text-align: center;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 10.5px;
                    font-weight: 500;
                    padding: .5em;
                    border: none;
                    height: 40px;
                    color: #0081c6;
                }

                th:nth-child(2) {
                    // min-width: 350px;
                    min-width: 296px
                }

                .months { 
                    width: 100px;
                }
            }

            .body {
                .cultureName {
                    border-top: 10px solid white;
                    color: #fff;
                }

                .cultureName:first-child {
                    border-top: none;
                }
                
                .cultureName p {
                    transform: rotate(270deg);
                    text-align: center;
                    margin: auto;
                    font-size: 15px;
                }
                
                .green--cultureName {
                    background: #8cc65d;
                    text-align: right;
                }
                
                .orange--cultureName {
                    background: #f68426;
                }
            }

            .body:before {
                content: "-";
                display: block;
                line-height: 15px;
                color: transparent;
            }
        }
    }
}

.t0 {
    table {
        .activity {
            opacity: 0;
        }
    }
}

.t1{
    width: 368px;
    position: absolute;
    top: 0px;
    overflow-x: hidden !important;
    background-color: #fff;

    table {
        .headRows {
            th.months {
                visibility: hidden;
            }
        }

        img {
            visibility: hidden;
        }
    }
}

@media(max-width: 768px) {
    .t1 {
        display: none;
    }

    .t0 {
        table {
            .headRows {
                th.months {
                    visibility: visible;
                }
            }
            .activity {
                opacity: 1;
            }
            img {
                visibility: visible;
            }
        }
    }
}