.logo .navbar{
    padding: 0px;
    // padding-bottom: 5px;
}

// .logo .search{
//     border:1px solid #0081c6;
//     padding-right: 10px;
//     border-radius: 5px;
// }

// .logo .search input{
//     border: none;
//     box-shadow: none;
//     font-size: 12px;
//     height:30px;
// }



.navbar-nav{
    width: 100%;
    display: flex;
}

.logo .navbar .nav-item{
    border-bottom: 3px solid transparent;
    transition: all .15s linear;
    flex-direction: column;
    width: 124px;  
}

.logo .navbar .nav-item a{
    font-size: 12px;
    text-align: center;
    font-weight: 600;
    font-weight: bold ;
    color: black;
    // padding: 10px 30px;
    padding-top: 20px;
}

.logo_ad_nav {
    -webkit-box-shadow: 0px 6px 5px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 6px 5px -3px rgba(0,0,0,0.75);
    box-shadow: 0px 6px 5px -3px rgba(163, 159, 159, 0.75);
    z-index: 1;
}

.logo .navbar .nav-item:hover{
    border-bottom: 3px solid #8cc65d;
    font-weight: bold;
}

.logo .search i{
    cursor: pointer;
    color: #646464;
}

.logo img{
    height: 60px;
}

// @media (max-width:970px) and (min-width:0px) {
//     .logo .navbar .nav-item {
//         width: 100%;
//         &:hover {
//             border-color: transparent;
//         }
//         a {
//             text-align: center;
//             font-weight: 600;
//             font-weight: bold ;
//             color: black;
//             text-align: left;
//             font-size: 13px;
//             padding: 10px 16px;
//             &:hover{
//                 text-decoration: none;
//                 border-color: transparent;
//             }
//         }
//     }

//     .dropdown-menu {
//         padding: 0 !important;
//         border: none !important;
//     }

//     .dropdown-item{
//         background-color: #f1f1f1 !important;
//     }

//     .t{
//         padding-left: 30px !important;
//     }
// }

.mobile-nav{
    display: none;
}

@media (max-width: 1200px){
    .web-nav{
        display: none;
    }

    .mobile-nav{
        display: block;
    }

    .logo .navbar .nav-item {
        width: 250px;
        margin-left: 20px;

        &:hover {
            border-color: transparent;
        }
        a {
            text-align: center;
            font-weight: 600;
            font-weight: bold ;
            color: black;
            text-align: left;
            font-size: 13px;
            padding: 10px 0px;
            &:hover{
                text-decoration: none;
                border-color: transparent;
            }
        }
    }

    .dropdown-menu {
        padding: 0 !important;
        border: none !important;
    }

    .dropdown-item{
        background-color: #f1f1f1 !important;
    }

    .t{
        padding-left: 20px !important;
    }
}

.activated-nn{
    color: #0081c6 !important;
    font-weight: bold !important;
}